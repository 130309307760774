<template>
  <div class="c-avatar" :style="styles">
    <div>{{initials}}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { hexToRgb } from "tools";

export default {
  name: "c-avatar",
  components: {},
  props: {
    name: { type: String, require: true },
    color: { type: String, default: "#000000" }
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([]),
    initials() {
      let initials = this.name.match(/\b\w/g) || [];
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    },
    styles() {
     const rgb = hexToRgb(this.color || '#000000')
      return {
        color: this.color,
        backgroundColor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.1)`,
        border: `1px solid rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.3)`
      }
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.c-avatar {
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 22px;

  @each $name, $color in $contacts-colors {
    &--#{$name} {
      color: var(--#{$name});
      background-color: rgba($color, 0.1);
    }
  }
}
</style>
