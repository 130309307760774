<template>
  <VueEditor
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    @input="handleInput"
    :editor-toolbar="customToolbar"
    :editor-options="editorSettings"
    class="flex column editor"
  />
</template>

<script>
import { debounce } from "lodash";

export default {
  name: 'cRichEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
    submitModel: {
      type: String,
      default: null,
    },
    submitPath: {
      type: String,
      default: null,
    },
    name: { 
      type: String,
      default: ''
    },
  },
  data: () => ({
    customToolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    // ['blockquote', 'code-block'],
    // ['link', 'image', 'video', 'formula'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    // ['clean']
    ],
    editorSettings: {
      formats: [
        'background',
        'bold',
        'color',
        'font',
        'code',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'code-block',
        'formula'
        // 'image'
        // 'video'
      ]
    }
  }),
  watch: {
    inputValue(newValue, oldValue) {
      // This function is called whenever `inputValue` changes.
      this.onChange(newValue);
    }
  },
  methods: {
    handleInput: debounce(function(newValue) {
      // This method is triggered every time VueEditor emits an input event.
      // Here you can add your custom onChange logic.
      if(this.submitPath && this.submitModel){
        if(this.submitPath && this.submitModel){
          this.$http.patch(this.submitPath, {
            [this.submitModel]: { [this.name]: newValue }
          })
          .then(
            response => {
              this.$emit("updated", response.body)
            },
            response => {
              for (const error in response.body) {
                this.$set(this.$data.errors, error, response.body[error][0]);
              }
            }
          );
        }
      }
      // Emit input event to support v-model in the parent.
      this.$emit('input', newValue);
      this.$emit("change", newValue);
    }, 400),
  }
};
</script>
