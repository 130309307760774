import Vue from "vue/dist/vue.esm";

const event_bus = new Vue()

window.EventBus = event_bus

export default {
  install(Vue) {
    Vue.prototype.$bus = event_bus
  }
}
