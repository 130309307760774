<template>
  <div class="c-x-button">
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="ui_customers_new"
          transform="translate(-926.000000, -146.000000)"
        >
          <g id="Group-21" transform="translate(455.000000, 116.000000)">
            <g
              id="components/icons/close"
              transform="translate(471.000000, 30.000000)"
            >
              <g id="Group">
                <rect
                  id="Rectangle"
                  stroke="#E1E1E7"
                  x="0.5"
                  y="0.5"
                  width="29"
                  height="29"
                  rx="2"
                ></rect>
                <path
                  d="M22.6262266,7.63813131 L22.3618687,7.37377345 C21.9713444,6.98324916 21.3381794,6.98324916 20.9476551,7.37377345 L15,13.3214286 L15,13.3214286 L9.05234488,7.37377345 C8.66182058,6.98324916 8.02865561,6.98324916 7.63813131,7.37377345 L7.37377345,7.63813131 C6.98324916,8.02865561 6.98324916,8.66182058 7.37377345,9.05234488 L13.3214286,15 L13.3214286,15 L7.37377345,20.9476551 C6.98324916,21.3381794 6.98324916,21.9713444 7.37377345,22.3618687 L7.63813131,22.6262266 C8.02865561,23.0167508 8.66182058,23.0167508 9.05234488,22.6262266 L15,16.6785714 L15,16.6785714 L20.9476551,22.6262266 C21.3381794,23.0167508 21.9713444,23.0167508 22.3618687,22.6262266 L22.6262266,22.3618687 C23.0167508,21.9713444 23.0167508,21.3381794 22.6262266,20.9476551 L16.6785714,15 L16.6785714,15 L22.6262266,9.05234488 C23.0167508,8.66182058 23.0167508,8.02865561 22.6262266,7.63813131 Z"
                  id="Path"
                  fill="#0281FF"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {name: "xButton" };
</script>


<style scoped lang="scss">
.c-x-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:active {
    background-color: rgba(0,0,0,0.05)
  }
}
</style>
