<template>
  <div class="flex jcenter" style="margin-top: 20px;">
    <div class="c-spinner"></div>
  </div>
</template>

<script>
export default {
  name: "c-spinner",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
