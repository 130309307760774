<template>
  <div class="c-search-select__container">
    <label v-if="this.label" class="c-label">{{ this.$props.label }} </label>
    <multiselect
      class="c-search-select"
      placeholder=""
      :maxHeight="400"
      v-model="selected"
      :options="optionsWithBlank"
      :multiple="false"
      :close-on-select="true"
      :show-labels="false"
      :loading="isLoading"
      @input="updateSelected"
      @search-change="query"
      :internal-search="false"
      :showNoResults="false"
      :disabled="disabled || readonly"
      :style="styles"
    >
      <template v-if="vendorIcon" v-slot:option="{ option }">
        <cMedia img="vendor-company" small>
          <div class="title1 flex acenter">
            {{ option.name }}
          </div>
        </cMedia>
      </template>
      <template v-else v-slot:option="{ option }"> {{ option.name }} </template>
      <template v-if="vendorIcon" v-slot:singleLabel="{ option }">
        <cMedia img="vendor-company" small>
          <div class="title1 flex acenter">
            {{ option.name }}
          </div>
        </cMedia>
      </template>
      <template v-else v-slot:singleLabel="{ option }">
        {{ option.name }}
      </template>
    </multiselect>
  </div>
</template>

<script>
// https://vue-multiselect.js.org
import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";
import Multiselect from "vue-multiselect";
import Routes from "packs/routes";
import { debounce, uniqBy } from "lodash";

Vue.use(VueResource);
export default Vue.component("c-search-select", {
  components: { Multiselect },
  props: {
    vendorIcon: {
      type: Boolean,
      default: false
    },
    withoutBlank: {
      type: Boolean,
      default: false
    },
    value: [String, Number],
    name: String,
    label: String,
    submitPath: String,
    submitModel: String,
    readonly: Boolean,
    disabled: Boolean,
    args: {
      type: Object,
      default: () => ({})
    },
    searchFor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      options: [],
      selected: null,
      errors: {}
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.$http.get(this.path.single(this.value)).then(response => {
          const { name, value } = response.body;
          this.options = uniqBy([{ name, value }, ...this.options], "value");
          this.selected = this.options.find(o => o.value == this.value);
        });
      } else {
        this.selected = null;
      }
    },
    args: {
      deep: true,
      handler() {
        this.query();
      }
    }
  },
  mounted() {
    if (this.value) {
      this.$http.get(this.path.single(this.value)).then(response => {
        const { name, value } = response.body;
        this.options = uniqBy([{ name, value }, ...this.options], "value");
        this.selected = this.options.find(o => o.value == this.value);
      });
    }

    Object.keys(this.args).forEach(
      key => this.args[key] === null && delete this.args[key]
    );
    this.$http.get(this.path.index(this.args)).then(response => {
      this.options = uniqBy([...this.options, ...response.body], "value");
      this.$data.isLoading = false;
    });
  },
  computed: {
    styles() {
      if (this.readonly) {
        return { opacity: 1 };
      } else {
        return {};
      }
    },
    path() {
      return {
        contact: {
          index: Routes.contact_searches_path,
          single: Routes.contact_search_path
        },
        team_member_contact: {
          index: Routes.team_member_searches_path,
          single: Routes.team_member_search_path
        },
        vendor: {
          index: Routes.vendor_searches_path,
          single: Routes.vendor_search_path
        }
      }[this.searchFor];
    },
    optionsWithBlank() {
      return this.withoutBlank
        ? this.options
        : [{ name: "", value: "" }, ...this.options];
    }
  },
  methods: {
    query: debounce(function(q) {
      this.$data.isLoading = true;

      Object.keys(this.args).forEach(
        key => this.args[key] === null && delete this.args[key]
      );
      this.$http
        .get(this.path.index(this.args), { params: { s: q } })
        .then(response => {
          this.options = response.body;
          this.$data.isLoading = false;
        });
    }, 300),

    updateSelected() {
      const val = (this.$data.selected || {}).value;
      const name = (this.$data.selected || {}).name;
      this.$emit("nameChanged", name);
      this.$emit("input", val);
      this.$emit("change", val);
      if (this.$props.submitPath) {
        this.$http
          .patch(this.$props.submitPath, {
            [this.$props.submitModel]: { [this.$props.name]: val }
          })
          .then(
            () => {},
            response => {
              for (const error in response.body) {
                this.$set(this.$data.errors, error, response.body[error][0]);
              }
            }
          );
      }
    }
  }
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.c-search-select {
  $root: &;
  border: none;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0px;
  }

  &::v-deep .multiselect {
    $multiselect: &;
    @mixin badge {
      text-transform: uppercase;
      padding: 7px;
      margin-right: 10px;
      border-radius: 5px;
      height: 27px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 5px;
      max-width: 100%;
      text-overflow: ellipsis;
      margin: 0px;
      font-size: 14px;
    }

    &__tags {
      padding: 8px 40px 8px 8px;
      border: 1px solid var(--gray2);
      border-radius: 6px;
      height: 46px;
      display: flex;
      align-items: center;

      @at-root #{$root}--error#{&} {
        border: 1px solid red;
      }

      &:hover {
        border: 1px solid #1c1c36;
        @at-root #{$root}--error#{&} {
          border: 1px solid red;
        }
      }

      &__input {
        background-color: #fff;
        margin-bottom: 0px;
      }

      &__tag {
        background-color: var(--gray4);
        text-transform: uppercase;
        font-weight: 700;
      }

      &__tag-icon {
        background-color: var(--gray4);
        &:after {
          color: var(--dark);
        }
      }
    }

    &__single {
      padding: 4px 8px 2px 8px;
      margin: 0px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @at-root #{$root}--category#{&} {
        @include badge;
        display: flex;
        width: unset;
        align-items: center;
        color: white;
        background-color: var(--dark);
        display: block !important;
        line-height: 13px;
      }
      @at-root #{$root}--area#{&} {
        @include badge;
        display: flex;
        width: unset;
        align-items: center;
        color: white;
        background-color: #adb3c4;
        display: block !important;
        line-height: 13px;
      }
    }

    &__content-wrapper {
      border: none;
    }

    &__option {
      background-color: #fff;
      border: 1px solid var(--gray2);
      border-bottom: 0;
      border-radius: 5px;
      text-overflow: ellipsis;
      overflow: hidden;

      @at-root #{$root}--category#{&} {
        color: white;
      }

      @at-root #{$root}--area#{&} {
        color: white;
      }

      span {
        font-weight: 700;
        @at-root #{$root}--category#{&} {
          @include badge;
          background-color: var(--dark);
        }
        @at-root #{$root}--area#{&} {
          @include badge;
          background-color: #adb3c4;
        }
      }

      &--selected {
        background-color: var(--gray1);
        color: var(--blue);

        @at-root #{$root}--category#{&} {
          color: white;
        }

        @at-root #{$root}--area#{&} {
          color: white;
        }
      }

      &--highlight {
        background-color: var(--gray1);
        color: var(--blue);
        @at-root #{$root}--category#{&} {
          color: white;
        }

        @at-root #{$root}--area#{&} {
          color: white;
        }
      }
    }

    &__select {
      background-color: transparent !important;
      &:before {
        border-style: none;
        margin: 0px;
        position: absolute;
        right: 10px;
        top: 11px;
        color: var(--blue);
        font-size: 21px;
        content: "⌄";
      }
    }

    &__content {
      border-bottom: 1px solid var(--gray2);
      width: 100%;
    }

    &__placeholder {
      font-size: 16px;
      color: var(--black);
      font-weight: 500;
      margin-bottom: 2px;
      margin-left: 5px;
    }
  }
}
</style>
