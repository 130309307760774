<template>
  <div class="c-labeled-textarea c-labeled-textarea__container">
  <label v-if="label" class="c-label">
    {{ this.label }}
    <span v-if="required" class="c-labeled-textarea__required">●</span>
  </label>
  <Textarea
    class="c-labeled-textarea__textarea"
    :class="classes"
    :name="this.$props.name"
    :value="value"
    :placeholder="placeholder"
    :disabled="disabled"
    @input="updateValue($event.target.value)"
    @change="submit($event.target.value)"
    @keyup="$emit('keyup', $event)"
  />
</div>
</template>

<script>
import autosize from "autosize";

export default {
  name: "c-labeled-textarea",
  mounted() {
    autosize(this.$el);
  },
  components: {},
  props: {
    required: Boolean,
    label: String,
    value: String,
    disabled: Boolean,
    invalid: Boolean,
    placeholder: String,
    name: String,
    submitPath: String,
    submitModel: String
  },
  data() {
    return {};
  },
  computed: {
    classes() {
      return {
        "c-labeled-textarea__resize-disabled": !this.resize
      }
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    submit(value) {
      this.$emit("change", value);
      if (this.$props.submitPath && !this.$props.invalid) {
        this.$http
          .patch(this.$props.submitPath, {
            [this.$props.submitModel]: { [this.$props.name]: this.value }
          })
          .then(
            () => {},
            response => {
              for (const error in response.body) {
                this.$set(this.$data.errors, error, response.body[error][0]);
              }
            }
          );
      }
    }
  }
};
</script>

<style scoped lang="scss">
.c-labeled-textarea {
  &__container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__resize-disabled {
    resize: none;
  }

  &__textarea {
    flex: 1 1;
    padding: 20px;
    background-color: var(--gray1);
    border: 1px solid var(--gray2);
    border-radius: 6px;
    width: 100%;
    color: var(--gray3);
    font-size: 14px;
    line-height: 1.2;

    &:hover {
      border: 1px solid var(--dark);
    }
  }

}
</style>
