<template>
  <div
    class="flex"
    style="align-items: center;height: 100%"
    @click="showOptions = !showOptions"
  >
    <div
      @click="showOptions = !showOptions"
      class="user-options__arrow"
      :class="{ 'user-options__arrow--active': showOptions }"
    >
      <span @click="showOptions = !showOptions">⌄</span>
    </div>
    <div
      @click="showOptions = !showOptions"
      class="user-options__people"
      :class="{ 'user-options__people--active': showOptions }"
    >
      <img
        @click="showOptions = !showOptions"
        :src="require('base/people.svg')"
      />
    </div>
    <div
      v-if="showOptions"
      class="user-options__options user-options__options--floating"
    >
      <div v-for="option in options" :key="option.name">
        <a
          class="user-options__option"
          :class="option.class"
          @click="option.action"
        >
          <span>{{ option.name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";
Vue.use(VueResource);
import Routes from "packs/routes";

export default Vue.component("cUserOptions", {
  props: {
    currentUser: Object
  },
  data() {
    return {
      showOptions: false,
      options: [
        {
          name: "Profile",
          action: this.goToProfile,
          class: "user-options__option--logout"
        },
        {
          name: "Logout",
          action: this.logout,
          class: "user-options__option--logout"
        }
      ]
    };
  },
  methods: {
    goToProfile() {
      window.location.href = Routes.profile_path(this.currentUser.id);
    },
    logout() {
      this.$http.delete(Routes.logout_path()).then(
        () => {
          window.location.href = Routes.sign_in_path({ logged_out: true });
        },
        () => {}
      );
    }
  }
});
</script>

<style lang="scss" scoped>
.user-options {
  &__option {
    @include title1;
    &--logout {
      color: #007bff;
    }
  }
  &__arrow {
    @include media("<tablet") {
      display: none;
    }
    color: var(--blue);
    font-size: 26px;
    cursor: pointer;
    line-height: 14px;
    height: 24px;

    &--active {
      transform: rotate(180deg);
    }
  }

  &__people {
    @include media(">=tablet") {
      display: none;
      margin-left: 10px;
    }
  }

  &__options {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 5px;
    > * {
      display: contents;
    }

    &--floating {
      position: absolute;
      background-color: white;
      padding: 10px;
      border-radius: 6px;
      top: 45px;
      right: 20px;
      z-index: 1;
      box-shadow: -1px 11px 31px -1px rgba(0, 0, 0, 0.33);
    }
  }
}
</style>
