<template>
  <div
    class="c-button"
    @click="!disabled && $emit('click')"
    @keyup.enter="!disabled && $emit('click')"
    :class="classes"
    :disabled="disabled"
    :style="customColorStyle"
  >
    <slot />
  </div>
</template>

<script>
  import { hexToRgb } from "tools";
  export default {
    name: "cButton",
    props: {
      white: Boolean,
      gray: Boolean,
      user: Boolean,
      add: Boolean,
      folder: Boolean,
      book: Boolean,
      big: Boolean,
      huge: Boolean,
      disabled: Boolean,
      chosen: Boolean,
      tag: Boolean,
      tool: Boolean,
      file: Boolean,
      fileWhite: Boolean,
      upload: Boolean,
      customColor: String
    },
    computed: {
      customColorStyle() {
        if(this.customColor === undefined){ return '' }
        return `color: ${this.customColor}; background-color: rgba(${hexToRgb(this.customColor)}, 0.1)`
      },
      classes() {
        return {
          "c-button--user": this.user,
          "c-button--file": this.file,
          "c-button--file-white": this.fileWhite,
          "c-button--white": this.white,
          "c-button--add": this.add,
          "c-button--folder": this.folder,
          "c-button--book": this.book,
          "c-button--big": this.big,
          "c-button--huge": this.huge,
          "c-button--gray": this.gray,
          "c-button--chosen": this.chosen,
          "c-button--tag": this.$props.tag,
          "c-button--tool": this.$props.tool,
          "c-button--upload": this.upload
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .c-button {
    &--big{
      padding: 10px 35px 10px 35px;
    }
    &--huge{
      height: 62px;
      max-width: 227px;
      padding: 30px 55px 30px 55px;
      &::after {
        left: 19px;
        top: 22px;
      }
    }
    &--chosen{
      background-color: white;
      border: 1px solid var(--blue);
    }
  }
</style>
