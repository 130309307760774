<template>
  <div class="c-media">
    <div>
      <img :src="src" :class="`c-media--${size}`">
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'cmedia',
  components: {
  },
  props: {
    mini: Boolean,
    small: Boolean,
    medium: Boolean,
    big: Boolean,
    img: {
      type: String,
      required: true
    }
  },
  computed: {
    size() {
      return this.mini && 'mini' ||
        this.small && 'small' ||
        this.medium && 'medium' ||
        'big'
    },
    src() {
      switch(this.img){
        case 'vendor-company':
          return {
            big: require('base/vendor_truck_big.svg'),
            medium: require('base/vendor_truck_medium.svg'),
            small: require('base/vendor_truck_small.svg')
          }[this.size]
        case 'builder-company':
          return {
            big: require('base/builder_tool_big.svg'),
            medium: require('base/builder_tool_modal.png'),
            small: require('base/builder_tool_small.svg')
          }[this.size]
        case 'builder':
          return {
            big: require('base/builder_big.svg'),
            medium: require('base/builder_medium.svg'),
            small: require('base/builder_small.svg')
          }[this.size]
        case 'customer-company':
          return {
            small: require('base/customer_company_small.svg'),
            big: require('base/customer_company_big.svg')
          }[this.size]
        case 'customer':
          return {
            small: require('base/customer_small.svg'),
            medium: require('base/customer.svg'),
            big: require('base/customer.svg')
          }[this.size]
        case 'team-member':
          return {
            small: require('base/teammember_small.svg'),
            medium: require('base/teammember_medium.svg'),
            big: require('base/teammember_big.svg')
          }[this.size]
        case 'subcontractor':
          return {
            small: require('base/subcontractor_small.svg'),
            medium: require('base/subcontractor_medium.svg'),
            big: require('base/subcontractor_big.svg')
          }[this.size]
        case 'subcontractor-company':
          return {
            small: require('base/subcontractors_small.svg'),
            big: require('base/subcontractors_big.svg')
          }[this.size]
        case 'date':
          return require("base/input_date.svg")
        default:
          return ''
      }
    }
  },
};
</script>

<style scoped lang="scss">
.c-media {
  &--mini{
    width: 16px;
    height: 18px;
  }
  &--small {
    width: 24px;
  }
  &--medium {
    width: 34px;
  }
  &--big {
    width: 54px;
  }
}
</style>
