<template>
  <div class="flex acenter">
    <slot />
    <label class="c-switch__container">
      <input type="checkbox">
      <span @click.stop="toggle" class="slider round"></span>
    </label>
  </div>
</template>

<script>
  export default {
    name: "c-toggle",
    props: {
      value: Boolean
    },
    data() {
      return {
        checked: this.value
      }
    },
    computed: {},
    methods: {
      toggle(){
        this.checked = !this.checked;
        this.$emit("change", this.checked);
        this.$emit("input", this.checked);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c-switch {
    &__container{
      position: relative;
      display: inline-block;
      width: 30px;
      height: 17px;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  input {
    &:checked {
      + {
        .slider {
          background-color: #2196F3;
          &:before {
            -webkit-transform: translateX(13px);
            -ms-transform: translateX(13px);
            transform: translateX(13px);
          }
        }
      }
    }
    &:focus {
      + {
        .slider {
          box-shadow: 0 0 1px #2196F3;
        }
      }
    }
  }
  .slider.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }

</style>
