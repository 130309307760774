<template>
  <div class="c-tabs">
    <slot
      v-for="tab in pTabs"
      :tab="tab"
      :active="String(tab[by]) == current"
    >
      <div
        :key="tab[by]"
        class="c-tabs__tab"
        :class="{'c-tabs__tab--active': String(tab[by]) == current}"
        @click="$emit('change', String(tab[by]))"
      >
        <span> {{tab.name}}</span>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'c-tabs',
  components: {
  },
  props: {
    by: {
      String,
      default: 'name'
    },
    current: String,
    tabs: Array
  },
  data() {
    return {
    };
  },
  computed: {
    pTabs() {
      if(typeof(this.tabs[0]) == 'object') return this.tabs;
      if(typeof(this.tabs[0]) == 'string') {
        return this.tabs.map((t) => ({name: t}))
      }
      return []
    }
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
.c-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @include media("<desktop") {
    max-width: 99vw;
  }

  &__tab {
    display: flex;
    padding: 10px 20px;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    font-weight: 400;
    font-size: 1.143rem;
    color: var(--gray4);
    border-bottom: 3px solid #fff;
    &:hover {
      span { transform: scale(1.1); }
    }
    &--active {
      font-weight: 500;
      color: var(--dark);
      font-size: 1.143rem;
      border-bottom: 3px solid #000;
    }
  }
}
</style>
