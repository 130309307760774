<template>
  <div :class="containerClasses" class="c-input c-input__container">
    <label v-if="label" class="c-input__label">
      {{ this.$props.label }}
      <span v-if="required" class="c-input__required">●</span>
    </label>
    <div class="flex c-input__value-chunk">
      <div v-if="leftSideSign" class="c-input__left_side_sign flex acenter mr4">
        {{ leftSideSign }}
      </div>
      <the-mask
        v-if="mask"
        :masked="true"
        :disabled="disabled"
        :mask="mask"
        type="tel"
        class="c-input__input"
        :autocomplete="autocomplete"
        :name="this.$props.name"
        :class="inputClasses"
        :value="value"
        :placeholder="placeholderText"
        @input="updateValue($event)"
        @change.native="submit($event)"
      />
      <!--TODO something here is not right with those events and then values used in methods. Need to investigate and simplify -->
      <input
        v-else
        class="c-input__input"
        :name="this.$props.name"
        :class="inputClasses"
        :value="value"
        :placeholder="placeholderText"
        :disabled="disabled"
        :type="type"
        :autocomplete="autocomplete"
        @input="updateValue($event.target.value)"
        @change="submit($event.target.value)"
        @keyup="$emit('keyup', $event)"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";
import VueTheMask from "vue-the-mask";

Vue.use(VueResource);
Vue.use(VueTheMask);

export default Vue.component("cInput", {
  props: {
    disabled: Boolean,
    email: Boolean,
    phone: Boolean,
    date: Boolean,
    search: Boolean,
    zipcode: Boolean,
    invalid: Boolean,
    required: Boolean,
    placeholder: String,
    leftSideSign: String,
    value: [String, Number],
    label: String,
    small: {
      type: Boolean,
    },
    name: String,
    submitPath: String,
    submitModel: String,
    type: String,
    autocomplete: String,
    enlarged: Boolean,
  },
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    updateValue(v) {
      this.$emit("input", v);
    },
    submit(v) {
      this.$emit("change", v);
      if (this.$props.submitPath && !this.$props.invalid) {
        this.$http
          .patch(this.$props.submitPath, {
            [this.$props.submitModel]: { [this.$props.name]: this.value },
          })
          .then(
            () => {},
            (response) => {
              for (const error in response.body) {
                this.$set(this.$data.errors, error, response.body[error][0]);
                this.$emit("error", response.body.error);
              }
            }
          );
      }
    },
  },
  computed: {
    mask() {
      if (this.$props.phone) return "(###) ###-####";
      if (this.$props.zipcode) return ["#####", "#####-####"];
      return false;
    },
    containerClasses() {
      return {
        "c-input__container--email": this.$props.email,
      };
    },
    inputClasses() {
      return {
        "c-input__input--small": this.$props.small,
        "c-input__input--error": this.$props.invalid,
        "c-input__input--email": this.$props.email,
        "c-input__input--date": this.$props.date,
        "c-input__input--phone": this.$props.phone,
        "c-input__input--search": this.$props.search,
        "c-input__input--disabled": this.$props.disabled,
        "c-input__input--enlarged": this.$props.enlarged,
      };
    },
    placeholderText() {
      if (this.$props.search) {
        return "Search";
      } else {
        return this.$props.placeholder;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.c-input {
  &__container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;

    &--email {
      &::after {
        content: url("~base/input_email.svg");
        position: absolute;
        bottom: 9px;
        left: 16px;
      }
    }
  }

  &__required {
    font-size: 14px;
    line-height: 0.9;
    color: var(--orange);
    margin-left: 2px;
  }

  &__label {
    display: block;
    font-size: 12px;
    color: var(--gray4);
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__input {
    $input: &;
    font-weight: 500;
    width: 100%;
    padding-left: 8px;
    background-color: var(--gray1);
    border: 1px solid var(--gray2);
    border-radius: 6px;
    height: 46px;
    line-height: 46px;

    &:focus {
      outline: none;
      background-color: var(--gray1);
      border: 1px solid #1c1c36;
    }

    &:hover {
      border: 1px solid #1c1c36;
      background-color: #eeeef3;
    }

    &--disabled {
      background-color: var(--gray2);

      &:hover {
        background-color: var(--gray2);
        border: 1px solid var(--gray2);
      }
    }

    &--small {
      height: 40px;
      &:focus {
        background-color: #ffffff;
      }
    }

    &--enlarged {
      min-height: 52px;
    }

    &--error {
      border: 1px solid red !important;
    }

    &--search {
      // background: url("~base/search.svg") var(--gray1) no-repeat scroll;
      // background-position: calc(100% - 15px);
      // padding-right: 42px;
    }

    &--phone {
      background: url("~base/input_phone.svg") var(--gray1) no-repeat scroll
        12px 13px;
      padding-left: 42px;
    }

    &--email {
      padding-left: 50px;
    }

    &--date {
      background: url("~base/input_date.svg") var(--gray1) no-repeat scroll 15px
        12px;
      padding-left: 50px;
      background-color: white !important;
      &[disabled] {
        border: 1px solid transparent !important;
      }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__left_side_sign {
    font-size: 1.5rem;
  }

  &__value-chunk {
    gap: 10px;
  }
}
</style>
