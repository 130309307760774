<template>
  <textarea
    class="c-textarea"
    :class="classes"
    :name="this.$props.name"
    :value="value"
    :placeholder="placeholder"
    :disabled="disabled"
    @input="updateValue($event.target.value)"
    @change="submit($event.target.value)"
    @keyup="$emit('keyup', $event)"
  ></textarea>
</template>

<script>
import autosize from "autosize";

export default {
  name: "c-textarea",
  mounted() {
    autosize(this.$el);
  },
  components: {},
  props: {
    value: String,
    disabled: Boolean,
    invalid: Boolean,
    placeholder: String,
    name: String,
    submitPath: String,
    submitModel: String,
    noPadding: Boolean,
    biggerText: {
      type: Boolean,
      default: false
    },
    resize: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  computed: {
    classes() {
      return {
        "c-textarea__resize-disabled": !this.resize,
        "c-textarea__invalid": this.invalid,
        "c-textarea--bigger-text": this.biggerText,
        "c-textarea__padding": !this.noPadding
      };
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    submit(value) {
      this.$emit("change", value);
      if (this.$props.submitPath && !this.$props.invalid) {
        this.$http
          .patch(this.$props.submitPath, {
            [this.$props.submitModel]: { [this.$props.name]: this.value }
          })
          .then(
            () => {},
            response => {
              for (const error in response.body) {
                this.$set(this.$data.errors, error, response.body[error][0]);
              }
            }
          );
      }
    }
  }
};
</script>

<style scoped lang="scss">
.c-textarea {
  flex: 1 1;
  background-color: var(--gray1);
  border: 1px solid var(--gray2);
  border-radius: 6px;
  width: 100%;
  color: var(--gray4);
  font-size: 14px;
  line-height: 1.2;
  
  &--bigger-text {
    color: black;
    font-size: 16px;
    line-height: 1.3;
    font-weight: bold;
  }
  
  &__resize-disabled {
    resize: none;
  }
  
  &__invalid {
    border: 1px solid red !important;
  }
  
  &__padding {
    padding: 20px;
  }

  &:hover {
    border: 1px solid var(--dark);
  }
}
</style>
