<template>
  <div>
    <label class="c-label">{{ this.$props.label }} </label>
    <multiselect
      v-model="selected"
      class="c-multiselect"
      placeholder="Type..."
      :hide-selected="true"
      :maxHeight="400"
      :class="multiselectClasses"
      :options="selectOptions"
      :multiple="true"
      :close-on-select="false"
      :show-labels="false"
      :custom-label="customLabel"
      @input="updateSelected()"
    >
    </multiselect>
  </div>
</template>

<script>
// https://vue-multiselect.js.org
import Vue from 'vue/dist/vue.esm'
import VueResource from 'vue-resource'
import Multiselect from 'vue-multiselect'

Vue.use(VueResource)
export default Vue.component('cMultiselect', {
  props: {
    options: Array,
    namedOptions: Array,
    label: String,
    selectedOptions: Array,
    submitPath: String,
    submitModel: String,
    name: String,
  },
  components: { Multiselect },
  methods: {
    updateSelected() {
      this.$emit('input', this.$data.selected)
      if (this.$props.submitPath) {
        this.$http
          .patch(this.$props.submitPath, {
            [this.$props.submitModel]: { [this.name]: this.$data.selected },
          })
          .then(
            (response) => {},
            (response) => {
              for (const error in response.body) {
                this.$set(this.$data.errors, error, response.body[error][0])
              }
            }
          )
      }
    },
  },
  data() {
    return {
      errors: {},
      selected: this.$props.selectedOptions,
    }
  },
  computed: {
    multiselectClasses() {
      if (this.$props.label == 'Categories') {
        return 'category-multiselect'
      } else if (this.$props.label == 'Areas') {
        return 'area-multiselect'
      } else {
        return 'default-multiselect'
      }
    },
    customLabel() {
      return this.$props.namedOptions
        ? (opt) => (this.namedOptions.find((o) => o.value == opt) || {}).name
        : undefined
    },
    selectOptions() {
      return this.$props.namedOptions
        ? this.namedOptions.map((opt) => opt.value)
        : this.$props.options
    },
  },
})
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
// Label dependent start
.default-multiselect::v-deep .multiselect__tag {
  background-color: var(--gray1);
}
.category-multiselect::v-deep .multiselect__tag {
  background-color: var(--dark);
}
.area-multiselect::v-deep .multiselect__tag {
  background-color: var(--gray3);
}
.dark-multiselect-values .default-multiselect::v-deep .multiselect__tag {
  background-color: var(--dark);
}

.default-multiselect::v-deep .multiselect__option {
  span {
    background: var(--gray1);
  }
}
.category-multiselect::v-deep .multiselect__option {
  span {
    background: var(--dark);
  }
}
.area-multiselect::v-deep .multiselect__option {
  span {
    background: var(--gray3);
  }
}
.dark-multiselect-values .default-multiselect::v-deep .multiselect__option {
  span {
    background: var(--dark);
  }
}
// Label dependent end

.c-multiselect::v-deep .multiselect__tags {
  padding-left: 8px;
  background-color: var(--gray1);
  border: 1px solid var(--gray2);
  border-radius: 6px;
  margin: 0px;
  max-height: 90px;
  overflow-y: auto;
  .multiselect__input {
    font-weight: 500;
    background-color: var(--gray1);
  }
  .multiselect__tags-wrap {
    margin-bottom: 9px;
  }
  .multiselect__tag-icon {
    background-color: inherit;
    padding: 3px;
    &:after {
      color: #fff;
    }
  }
  .multiselect__tag {
    text-transform: uppercase;
    font-weight: 500;
    height: 30px;
    text-align: center;
    margin: 2px !important;
    span {
      display: flex;
      padding-top: 3px;
    }
  }
}

.c-multiselect::v-deep .multiselect__select {
  &:before {
    border-style: none;
    margin: 0px;
    position: absolute;
    right: 10px;
    top: 11px;
    color: var(--blue);
    font-size: 21px;
    content: '⌄';
  }
}
.c-multiselect::v-deep .multiselect__content-wrapper {
  border: none;
}

.c-multiselect::v-deep .multiselect__content {
  .multiselect__option {
    background-color: #fff;
    border: 1px solid var(--gray2);
    border-radius: 5px;
    text-overflow: ellipsis;

    &--highlight {
      background-color: var(--gray1);
    }
    span {
      text-transform: uppercase;
      padding: 7px;
      margin-right: 10px;
      border-radius: 5px;
      height: 27px;
      color: #fff;
      font-weight: 500;
      text-align: center;
      margin-bottom: 5px;
      max-width: 100%;
      text-overflow: ellipsis;
      margin: 0px;
      font-size: 14px;
    }
  }
}
</style>
