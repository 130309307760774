/* rails-erb-loader-dependencies ../config/routes */

var routes = {};

(function() {
    /*
File generated by js-routes 1.4.9
Based on Rails 6.0.6 routes of Customease::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// address => /addresses/:id(.:format)
  // function(id, options)
  address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// builder => /builders/:id(.:format)
  // function(id, options)
  builder_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"builders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// builders => /builders(.:format)
  // function(options)
  builders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"builders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// clone_project => /projects/clone(.:format)
  // function(options)
  clone_project_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[6,"clone",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_booked_jobs => /company_admin/booked_jobs(.:format)
  // function(options)
  company_admin_booked_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"booked_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_categories => /company_admin/categories(.:format)
  // function(options)
  company_admin_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_category => /company_admin/categories/:id(.:format)
  // function(id, options)
  company_admin_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_admin_company_detail => /company_admin/company_details/:id(.:format)
  // function(id, options)
  company_admin_company_detail_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"company_details",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_admin_company_details => /company_admin/company_details(.:format)
  // function(options)
  company_admin_company_details_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"company_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_financial_default => /company_admin/financial_defaults/:id(.:format)
  // function(id, options)
  company_admin_financial_default_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"financial_defaults",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_admin_financial_defaults => /company_admin/financial_defaults(.:format)
  // function(options)
  company_admin_financial_defaults_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"financial_defaults",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_installation_calculation => /company_admin/installation_calculations/:id(.:format)
  // function(id, options)
  company_admin_installation_calculation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"installation_calculations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_admin_installation_calculations => /company_admin/installation_calculations(.:format)
  // function(options)
  company_admin_installation_calculations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"installation_calculations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_milestone => /company_admin/milestones/:id(.:format)
  // function(id, options)
  company_admin_milestone_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"milestones",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_admin_milestones => /company_admin/milestones(.:format)
  // function(options)
  company_admin_milestones_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"milestones",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_partial_sales_goal => /company_admin/partial_sales_goals/:id(.:format)
  // function(id, options)
  company_admin_partial_sales_goal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"partial_sales_goals",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_admin_partial_sales_goals => /company_admin/partial_sales_goals(.:format)
  // function(options)
  company_admin_partial_sales_goals_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"partial_sales_goals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_payment_term => /company_admin/payment_terms/:id(.:format)
  // function(id, options)
  company_admin_payment_term_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"payment_terms",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_admin_payment_terms => /company_admin/payment_terms(.:format)
  // function(options)
  company_admin_payment_terms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"payment_terms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_pending_sales => /company_admin/pending_sales(.:format)
  // function(options)
  company_admin_pending_sales_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"pending_sales",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_project_detail => /company_admin/project_details/:id(.:format)
  // function(id, options)
  company_admin_project_detail_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"project_details",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_admin_project_details => /company_admin/project_details(.:format)
  // function(options)
  company_admin_project_details_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"project_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_sales_goal => /company_admin/sales_goals/:id(.:format)
  // function(id, options)
  company_admin_sales_goal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"sales_goals",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_admin_sales_goals => /company_admin/sales_goals(.:format)
  // function(options)
  company_admin_sales_goals_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"sales_goals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_sales_tax => /company_admin/sales_taxes/:id(.:format)
  // function(id, options)
  company_admin_sales_tax_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"sales_taxes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_admin_sales_taxes => /company_admin/sales_taxes(.:format)
  // function(options)
  company_admin_sales_taxes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"sales_taxes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_admin_team_member => /company_admin/team_members/:id(.:format)
  // function(id, options)
  company_admin_team_member_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"team_members",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_admin_team_members => /company_admin/team_members(.:format)
  // function(options)
  company_admin_team_members_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_admin",false],[2,[7,"/",false],[2,[6,"team_members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact => /contacts/:id(.:format)
  // function(id, options)
  contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact_search => /contact_searches/:id(.:format)
  // function(id, options)
  contact_search_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_searches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact_searches => /contact_searches(.:format)
  // function(options)
  contact_searches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_searches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// contacts => /contacts(.:format)
  // function(options)
  contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// contacts_customers => /contacts_customers(.:format)
  // function(options)
  contacts_customers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts_customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// customer => /customers/:id(.:format)
  // function(id, options)
  customer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// customer_contact => /customers/:customer_id/contacts/:id(.:format)
  // function(customer_id, id, options)
  customer_contact_path: Utils.route([["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_contacts => /customers/:customer_id/contacts(.:format)
  // function(customer_id, options)
  customer_contacts_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_info => /customers/:customer_id/info(.:format)
  // function(customer_id, options)
  customer_info_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_note => /customers/:customer_id/notes/:id(.:format)
  // function(customer_id, id, options)
  customer_note_path: Utils.route([["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_notes => /customers/:customer_id/notes(.:format)
  // function(customer_id, options)
  customer_notes_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_projects => /customers/:customer_id/projects(.:format)
  // function(customer_id, options)
  customer_projects_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"projects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customers => /customers(.:format)
  // function(options)
  customers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// dashboards => /dashboards(.:format)
  // function(options)
  dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// edit_builder => /builders/:id/edit(.:format)
  // function(id, options)
  edit_builder_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"builders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_contact => /contacts/:id/edit(.:format)
  // function(id, options)
  edit_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_customer => /customers/:id/edit(.:format)
  // function(id, options)
  edit_customer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_customer_note => /customers/:customer_id/notes/:id/edit(.:format)
  // function(customer_id, id, options)
  edit_customer_note_path: Utils.route([["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_dashboards => /dashboards/edit(.:format)
  // function(options)
  edit_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_go_back_service => /go_back_services/:id/edit(.:format)
  // function(id, options)
  edit_go_back_service_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"go_back_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_note => /notes/:id/edit(.:format)
  // function(id, options)
  edit_note_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_password => /edit_password(.:format)
  // function(options)
  edit_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"edit_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// edit_photo => /photos/:id/edit(.:format)
  // function(id, options)
  edit_photo_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"photos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_project => /projects/:id/edit(.:format)
  // function(id, options)
  edit_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_subcontractor => /subcontractors/:id/edit(.:format)
  // function(id, options)
  edit_subcontractor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"subcontractors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_team_member => /team_members/:id/edit(.:format)
  // function(id, options)
  edit_team_member_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"team_members",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_template => /templates/:id/edit(.:format)
  // function(id, options)
  edit_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_vendor => /vendors/:id/edit(.:format)
  // function(id, options)
  edit_vendor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"vendors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// email_confirmation => /email_confirmation(.:format)
  // function(options)
  email_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"email_confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// exports => /exports(.:format)
  // function(options)
  exports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// go_back_service => /go_back_services/:id(.:format)
  // function(id, options)
  go_back_service_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"go_back_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// go_back_service_apply_template => /go_back_services/:go_back_service_id/apply_template(.:format)
  // function(go_back_service_id, options)
  go_back_service_apply_template_path: Utils.route([["go_back_service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"go_back_services",false],[2,[7,"/",false],[2,[3,"go_back_service_id",false],[2,[7,"/",false],[2,[6,"apply_template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// go_back_service_product => /go_back_services/:go_back_service_id/products/:id(.:format)
  // function(go_back_service_id, id, options)
  go_back_service_product_path: Utils.route([["go_back_service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"go_back_services",false],[2,[7,"/",false],[2,[3,"go_back_service_id",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// go_back_service_products => /go_back_services/:go_back_service_id/products(.:format)
  // function(go_back_service_id, options)
  go_back_service_products_path: Utils.route([["go_back_service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"go_back_services",false],[2,[7,"/",false],[2,[3,"go_back_service_id",false],[2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// go_back_service_service => /go_back_services/:go_back_service_id/services/:id(.:format)
  // function(go_back_service_id, id, options)
  go_back_service_service_path: Utils.route([["go_back_service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"go_back_services",false],[2,[7,"/",false],[2,[3,"go_back_service_id",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// go_back_service_services => /go_back_services/:go_back_service_id/services(.:format)
  // function(go_back_service_id, options)
  go_back_service_services_path: Utils.route([["go_back_service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"go_back_services",false],[2,[7,"/",false],[2,[3,"go_back_service_id",false],[2,[7,"/",false],[2,[6,"services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// go_back_services => /go_back_services(.:format)
  // function(options)
  go_back_services_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"go_back_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// logout => /logout(.:format)
  // function(options)
  logout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// mark_all_notifications => /mark_all_notifications(.:format)
  // function(options)
  mark_all_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mark_all_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_contact => /contacts/new(.:format)
  // function(options)
  new_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_customer => /customers/new(.:format)
  // function(options)
  new_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_customer_note => /customers/:customer_id/notes/new(.:format)
  // function(customer_id, options)
  new_customer_note_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_dashboards => /dashboards/new(.:format)
  // function(options)
  new_dashboards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_go_back_service => /go_back_services/new(.:format)
  // function(options)
  new_go_back_service_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"go_back_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_note => /notes/new(.:format)
  // function(options)
  new_note_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_onboarding_company => /onboarding/company/new(.:format)
  // function(options)
  new_onboarding_company_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"company",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_password => /new_password(.:format)
  // function(options)
  new_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"new_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_photo => /photos/new(.:format)
  // function(options)
  new_photo_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"photos",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_project => /projects/new(.:format)
  // function(options)
  new_project_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_project_change_order => /projects/:project_id/change_orders/new(.:format)
  // function(project_id, options)
  new_project_change_order_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"change_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_project_go_back_service => /projects/:project_id/go_back_services/new(.:format)
  // function(project_id, options)
  new_project_go_back_service_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"go_back_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_template => /templates/new(.:format)
  // function(options)
  new_template_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// note => /notes/:id(.:format)
  // function(id, options)
  note_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// notes => /notes(.:format)
  // function(options)
  notes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// notification => /notifications/:id(.:format)
  // function(id, options)
  notification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// onboarding_company => /onboarding/company(.:format)
  // function(options)
  onboarding_company_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"company",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// order => /orders/:id(.:format)
  // function(id, options)
  order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// orders => /orders(.:format)
  // function(options)
  orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// password => /password(.:format)
  // function(options)
  password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// photo => /photos/:id(.:format)
  // function(id, options)
  photo_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"photos",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// photos => /photos(.:format)
  // function(options)
  photos_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"photos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// product => /products/:id(.:format)
  // function(id, options)
  product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// products => /products(.:format)
  // function(options)
  products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// profile => /profiles/:id(.:format)
  // function(id, options)
  profile_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"profiles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project => /projects/:id(.:format)
  // function(id, options)
  project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_apply_template => /projects/:project_id/apply_template(.:format)
  // function(project_id, options)
  project_apply_template_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"apply_template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_change_order => /projects/:project_id/change_orders/:id(.:format)
  // function(project_id, id, options)
  project_change_order_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"change_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// project_change_order_contract => /projects/:project_id/change_orders/:change_order_id/contract(.:format)
  // function(project_id, change_order_id, options)
  project_change_order_contract_path: Utils.route([["project_id",true],["change_order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"change_orders",false],[2,[7,"/",false],[2,[3,"change_order_id",false],[2,[7,"/",false],[2,[6,"contract",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// project_change_orders => /projects/:project_id/change_orders(.:format)
  // function(project_id, options)
  project_change_orders_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"change_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_check_install_fees => /projects/:project_id/check_install_fees(.:format)
  // function(project_id, options)
  project_check_install_fees_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"check_install_fees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_contract => /projects/:project_id/contract(.:format)
  // function(project_id, options)
  project_contract_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"contract",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_delivery_order => /projects/:project_id/delivery_order(.:format)
  // function(project_id, options)
  project_delivery_order_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"delivery_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_document => /projects/:project_id/documents/:id(.:format)
  // function(project_id, id, options)
  project_document_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// project_documents => /projects/:project_id/documents(.:format)
  // function(project_id, options)
  project_documents_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_estimate => /projects/:project_id/estimate(.:format)
  // function(project_id, options)
  project_estimate_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"estimate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_go_back_order_document => /projects/:project_id/go_back_order_document(.:format)
  // function(project_id, options)
  project_go_back_order_document_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"go_back_order_document",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_go_back_service => /projects/:project_id/go_back_services/:id(.:format)
  // function(project_id, id, options)
  project_go_back_service_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"go_back_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// project_go_back_services => /projects/:project_id/go_back_services(.:format)
  // function(project_id, options)
  project_go_back_services_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"go_back_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_invoice => /projects/:project_id/invoice(.:format)
  // function(project_id, options)
  project_invoice_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_milestone => /projects/:project_id/milestones/:id(.:format)
  // function(project_id, id, options)
  project_milestone_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"milestones",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// project_orders_statuses => /projects/:project_id/orders_statuses(.:format)
  // function(project_id, options)
  project_orders_statuses_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"orders_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_product => /project_products/:id(.:format)
  // function(id, options)
  project_product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_products => /project_products(.:format)
  // function(options)
  project_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project_products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// project_purchase_order => /projects/:project_id/purchase_orders/:id(.:format)
  // function(project_id, id, options)
  project_purchase_order_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"purchase_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// project_sales_order => /projects/:project_id/sales_order(.:format)
  // function(project_id, options)
  project_sales_order_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"sales_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_service => /project_services/:id(.:format)
  // function(id, options)
  project_service_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_services => /project_services(.:format)
  // function(options)
  project_services_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"project_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// projects => /projects(.:format)
  // function(options)
  projects_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// reports => /reports(.:format)
  // function(options)
  reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// reports_generate_csv => /reports/:report_type/generate_csv(.:format)
  // function(report_type, options)
  reports_generate_csv_path: Utils.route([["report_type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_type",false],[2,[7,"/",false],[2,[6,"generate_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports_generate_pdf => /reports/:report_type/generate_pdf(.:format)
  // function(report_type, options)
  reports_generate_pdf_path: Utils.route([["report_type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_type",false],[2,[7,"/",false],[2,[6,"generate_pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reset_password => /reset_password(.:format)
  // function(options)
  reset_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rich_text_note => /rich_text_notes/:id(.:format)
  // function(id, options)
  rich_text_note_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rich_text_notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// service => /services/:id(.:format)
  // function(id, options)
  service_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// services => /services(.:format)
  // function(options)
  services_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// sign_in => /sign_in(.:format)
  // function(options)
  sign_in_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// subcontractor => /subcontractors/:id(.:format)
  // function(id, options)
  subcontractor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"subcontractors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// subcontractors => /subcontractors(.:format)
  // function(options)
  subcontractors_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subcontractors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// super_admin_companies => /super_admin/companies(.:format)
  // function(options)
  super_admin_companies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"super_admin",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// super_admin_newsletters => /super_admin/newsletters(.:format)
  // function(options)
  super_admin_newsletters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"super_admin",false],[2,[7,"/",false],[2,[6,"newsletters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// super_admin_team_member => /super_admin/team_members/:id(.:format)
  // function(id, options)
  super_admin_team_member_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"super_admin",false],[2,[7,"/",false],[2,[6,"team_members",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// super_admin_team_members => /super_admin/team_members(.:format)
  // function(options)
  super_admin_team_members_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"super_admin",false],[2,[7,"/",false],[2,[6,"team_members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// team_member => /team_members/:id(.:format)
  // function(id, options)
  team_member_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"team_members",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// team_member_search => /team_member_searches/:id(.:format)
  // function(id, options)
  team_member_search_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"team_member_searches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// team_member_searches => /team_member_searches(.:format)
  // function(options)
  team_member_searches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"team_member_searches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// team_members => /team_members(.:format)
  // function(options)
  team_members_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"team_members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// template => /templates/:id(.:format)
  // function(id, options)
  template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// templates => /templates(.:format)
  // function(options)
  templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// vendor => /vendors/:id(.:format)
  // function(id, options)
  vendor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"vendors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// vendor_search => /vendor_searches/:id(.:format)
  // function(id, options)
  vendor_search_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"vendor_searches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// vendor_searches => /vendor_searches(.:format)
  // function(options)
  vendor_searches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"vendor_searches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// vendors => /vendors(.:format)
  // function(options)
  vendors_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"vendors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// verification => /verification(.:format)
  // function(options)
  verification_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"verification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// verify => /verify(.:format)
  // function(options)
  verify_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"verify",false],[1,[2,[8,".",false],[3,"format",false]],false]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("namespace" + ".defaults is removed. Use " + "namespace" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "namespace", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);

}.call(routes));

// Example:
// import Routes from "packs/routes";
// Routes.customer_info_path(5) => "/customers/5/info"

export default routes.namespace;
