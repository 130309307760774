<template>
  <div
    @blur="show = false"
    tabindex="-1"
    class="notifications"
    :class="{ 'notifications--mobile': mobile }"
  >
    <div @click="show = !show" class="notifications__container">
      <div class="notifications__circle">
        <img :src="require('base/bell.svg')" />
        <span v-if="unreadCount" class="notifications__indicator">
          {{ unreadCount }}
        </span>
      </div>
    </div>
    <div v-show="show" class="notifications__list">
      <div v-if="notifications.length">
        <div class="title4 mb5 flex flex-end">
          <span style="cursor: pointer" @click="markAllNotifications">
            Mark all as read
          </span>
        </div>
        <NotificationsItem
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
          @destroy="destroy"
          @mark="mark"
        />
      </div>
      <div class="notifications__none" v-else>
        You haven’t received any notifications yet
      </div>
    </div>
  </div>
</template>

<script>
import NotificationsItem from "./notifications-item";

import Routes from "packs/routes";
import { mapGetters } from "vuex";
import { ifConfirmed } from "tools/confirmation";

export default {
  name: "notifications",
  components: {
    NotificationsItem
  },
  props: {
    mobile: { type: Boolean, default: false },
    currentUser: Object
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    unreadCount() {
      return this.currentUser.notifications.filter(n => !n.read).length;
    },
    notifications() {
      return this.currentUser.notifications;
    }
  },
  methods: {
    mark(id) {
      this.$http.patch(Routes.notification_path(id), { read: true });
      this.$emit("update:currentUser", {
        ...this.currentUser,
        notifications: this.currentUser.notifications.map(n =>
          n.id != id
            ? n
            : {
                ...n,
                read: true
              }
        )
      });
    },
    destroy(id) {
      this.$http.delete(Routes.notification_path(id)).then(
        () => {
          this.$emit("update:currentUser", {
            ...this.currentUser,
            notifications: this.currentUser.notifications.filter(
              n => n.id != id
            )
          });
        }
      );
    },
    markAllNotifications() {
      this.$emit("update:currentUser", {
        ...this.currentUser,
        notifications: this.currentUser.notifications.map(n => ({
          ...n,
          read: true
        }))
      });
      this.$http.patch(Routes.mark_all_notifications_path());
    }
  }
};
</script>

<style scoped lang="scss">
.notifications {
  $root: &;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__circle {
    width: 45px;
    height: 45px;
    border-radius: 33px;
    display: flex;
    justify-content: center;
    align-items: center;

    #{$root} .notifications__container:hover & {
      border: 1px solid rgba(2, 129, 255, 0.2);
      background: rgba(2, 129, 255, 0.03);
    }

    #{$root} .notifications__container:active & {
      border: 1px solid rgba(2, 129, 255, 0.2);
      background: rgba(2, 129, 255, 0.1);
    }
  }

  &__container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__list {
    position: absolute;
    left: 0px;
    top: 69px;
    z-index: 1;
    background-color: white;
    padding: 5px;
    padding-bottom: 0px;
    box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);

    #{$root}--mobile & {
      left: -230px;
      top: 50px;
    }

    /* &::before { */
    /*   content: ""; */
    /*   width: 68px; */
    /*   height: 10px; */
    /*   position: absolute; */
    /*   top: -6px; */
    /*   left: 0px; */
    /*   background-color: white; */
    /* } */
  }

  &__indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: absolute;
    right: 13px;
    top: 13px;
    font-size: 12px;
    background-color: #f77242;
    background-color: var(--orange);
    border-radius: 10px;
    color: white;
    width: 20px;
    height: 20px;
    text-align: center;
  }

  &__none {
    width: 315px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray5);
    border-radius: 6px;
    margin-bottom: 5px;
  }
}
</style>
