<template>
  <div class="c-icon-badge">
    <img :src="src">
    <div class="ellipsis">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'c-icon-badge',
  components: {
  },
  props: {
    phone: Boolean,
    email: Boolean,
    chart: Boolean
  },
  computed: {
    src() {
      if(this.phone) {return require('base/customer_phone.svg')}
      else if(this.email) {return require('base/input_email.svg')}
      else if(this.chart) {return require('base/bar_chart.svg')}
    }
  }
};
</script>
