// triggers event when click outside of element occured
export default {
  bind(element, binding, vnode) {
    element.clickOutsideEvent = event => {
      if (element === event.target || element.contains(event.target)) {
        return;
      }

      const methodName = binding.expression;
      vnode.context[methodName](event);
    };

    document.body.addEventListener(
      'mousedown',
      element.clickOutsideEvent,
      false
    );
    document.body.addEventListener(
      'touchstart',
      element.clickOutsideEvent,
      false
    );
  },

  unbind(element) {
    document.body.removeEventListener(
      'mousedown',
      element.clickOutsideEvent,
      false
    );
    document.body.removeEventListener(
      'touchstart',
      element.clickOutsideEvent,
      false
    );
  }
};
