<template>
  <div @click="toggle" class="c-checkbox__container">
    <div class="c-checkbox" :checked="$data.checked" :class="classes" />
    <div class="title1">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: "c-checkbox",
  props: {
    value: Boolean,
    label: String,
    name: String,
    submitPath: String,
    submitModel: String,
    alternate: Boolean,
    small: Boolean,
    viewOnly: Boolean
  },
  data() {
    return {
      checked: this.$props.value
    };
  },
  watch: {
    value(newVal) {
      this.checked = newVal;
    }
  },
  computed: {
    classes() {
      return {
        "c-checkbox--alternate-unchecked": this.alternate && !this.value,
        "c-checkbox--small": this.small
      };
    }
  },
  methods: {
    toggle() {
      if (this.viewOnly) {
        return;
      }
      this.$data.checked = !this.$data.checked;
      this.$emit("change", this.$data.checked);
      this.$emit("input", this.$data.checked);
      this.update();
    },
    update() {
      if (!this.$props.submitPath) return;
      this.$http
        .patch(this.$props.submitPath, {
          [this.$props.submitModel]: {
            [this.$props.name]: this.$data.checked
          }
        })
        .then(
          () => {},
          response =>
            response.body.forEach(error => {
              this.$set(this.$data.errors, error, response.body[error][0]);
            })
        );
    }
  }
};
</script>

<style lang="scss" scoped>
.c-checkbox {
  &__container {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
}
</style>
