// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
// require("channels");

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap";

import App from "tools/app";
import FlashMessage from "@smartweb/vue-flash-message";

import autosize from "autosize";
window.autosize = autosize;

import Rails from "@rails/ujs";
import $ from "jquery";
window.Rails = Rails;

import Notifications from "components/user_navbar/notifications";

// TODO remove before final views
window.autoSaveForm = function(form) {
  $(`${form} input, ${form} select, ${form} textarea`)
    .off("change")
    .on("change", e => {
      Rails.fire($(e.target).closest(form)[0], "submit");
    });
};

window.infiniteScroll = function(callback) {
  let iScrollData = { page: 1, pending: false };
  window.addEventListener("scroll", () => {
    let height = document.documentElement.offsetHeight;
    let offset = document.documentElement.scrollTop + window.innerHeight;

    if (offset > height - 50) {
      callback(iScrollData);
    }
  });
};

document.addEventListener("DOMContentLoaded", () => {
  let toggleNavbar = () => {
    let navbar = document.querySelector("nav.l-navbar");
    let shade = document.querySelector(".l-navbar__shade");

    if (navbar.classList.contains("l-navbar--show")) {
      navbar.classList.remove("l-navbar--show");
      shade.classList.remove("l-navbar__shade--show");
    } else {
      navbar.classList.add("l-navbar--show");
      shade.classList.add("l-navbar__shade--show");
    }
  };

  document
    .querySelectorAll(".j-navbar-toggle, .l-navbar::after, .l-navbar__shade")
    .forEach(button => {
      button.addEventListener("click", toggleNavbar);
    });
});

document.addEventListener("DOMContentLoaded", () => {
  document.addEventListener("click", event => {
    event.stopPropagation();
    if (event.target.classList.contains("c-button-dropdown")) {
      if (event.target.classList.contains("c-button-dropdown--active")) {
        event.target.classList.remove("c-button-dropdown--active");
        event.target.querySelector(
          ".c-button-dropdown__container"
        ).style.display = "none";
      } else {
        event.target.classList.add("c-button-dropdown--active");
        event.target.querySelector(
          ".c-button-dropdown__container"
        ).style.display = "block";
      }
      document.querySelectorAll(".c-button-dropdown").forEach(node => {
        if (node != event.target) {
          node.classList.remove("c-button-dropdown--active");
          node.querySelector(".c-button-dropdown__container").style.display =
            "none";
        }
      });
    } else {
      document.querySelectorAll(".c-button-dropdown").forEach(node => {
        node.classList.remove("c-button-dropdown--active");
        node.querySelector(".c-button-dropdown__container").style.display =
          "none";
      });
    }
  });
});

App.init(() => {
  if (document.getElementById("flash-messages") !== null) {
    new App.Vue({
      el: "#flash-messages",
      data() {
        return {
          flash: []
        };
      },
      components: { "flash-messages": FlashMessage },
      mounted() {
        App.Vue.set(this.$data, "flash", JSON.parse(this.$el.dataset.flash));
        this.$data.flash.forEach(msg => {
          this.flashMessage[msg[0]]({
            ...msg[1],
            blockClass: "z-index-on-top",
            wrapperClass: "z-index-on-top"
          });
          //using flashMessage.deleteMessage() does not do anything here, those elements get added into DOM for good
          window.setTimeout(() => {
            document.querySelectorAll("._vue-flash-msg-body").forEach(el => {
              el.style.display = "none";
            });
          }, 3000);
        });
      },
      created() {
        window.EventBus.$on("authError", () => {
          this.flashMessage.error({
            title: "Authorization error!",
            message: "You are not permitted to perform this action"
          });
        });
      }
    });
  }

  if (document.getElementById("csort") !== null) {
    new App.Vue({
      el: "#csort"
    });
  }
  if (document.getElementById("c-user-navbar") !== null) {
    new App.Vue({
      el: "#c-user-navbar"
    });
  }
  if (document.getElementById("c-user-options-mobile") !== null) {
    new App.Vue({
      el: "#c-user-options-mobile"
    });
  }

  if (document.getElementById("notifications-mobile") !== null) {
    new App.Vue({
      el: "#notifications-mobile",
      components: { Notifications }
    });
  }
});

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".c-delayed").forEach(node => {
    node.classList.remove("c-delayed");
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
