import Vue from "vue/dist/vue.esm";
import Vuex from "vuex";
import Rails from "@rails/ujs";
import registerBaseComponents from "./auto_register";
import VueResource from "vue-resource";
import FlashMessage from "@smartweb/vue-flash-message";
import VueCurrencyFilter from "vue-currency-filter";
import infiniteScroll from "vue-infinite-scroll";
import Vuelidate from "vuelidate";
import EventBus from "./event_bus";
import Vue2Editor from "vue2-editor";

import clickOutside from "directives/click_outside";

import date from "filters/date";

Vue.filter("date", date);
Vue.directive("click-outside", clickOutside);

Vue.use(EventBus);
Vue.use(infiniteScroll);
Vue.use(VueResource);
Vue.use(FlashMessage);
Vue.use(Vuelidate);
Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: false,
});
Vue.use(Vuex);
Vue.use(Vue2Editor);

const init = (callback) => {
  window.addEventListener("DOMContentLoaded", () => {
    registerBaseComponents(Vue);
    Vue.http.headers.common["X-CSRF-Token"] = Rails.csrfToken();
    if (callback) callback();
  });
};

export default {
  Vue,
  init,
};
