<template>
  <transition name="modal">
    <div class="rich-text-note-modal modal modal--gray">
      <div class="modal-mask">
        <div class="modal-wrapper" @click.self="$emit('close')">
          <div class="rich-text-note-modal__container modal-container">
            <div
              class="rich-text-note-modal__header modal-header space-between acenter flex mr10"
            >
              <div class="flex acenter" v-if="photo">
                <cInput
                  class="mr15"
                  v-model="name"
                  name="name"
                  :submit-path="path"
                  submit-model="photo"
                  @change="updatePhoto"
                  v-if="edit"
                />
                <h2 class="mr15" style="word-break: break-all" v-else>
                  {{ name }}
                </h2>
                <cIcon
                  class="mr10 hide-p"
                  @click.native.stop="edit = !edit"
                  img="edit"
                />
                <cIcon
                  class="mr10"
                  button
                  img="download"
                  @click.native.stop="downloadFile"
                />
                <cIcon class="mr10" button @click.native.stop="deletePhoto" img="trash" />
              </div>
              <cXbutton @click.native="$emit('close')" />
            </div>
            <div class="rich-text-note-modal__body modal-body">
              <div
                @click="$emit('change', -1)"
                class="rich-text-note-modal__previous"
              >
                ❮
              </div>
              <div
                @click="$emit('change', 1)"
                class="rich-text-note-modal__next"
              >
                ❯
              </div>
              <img ref="img" :src="photo.url" class="rich-text-note-modal__img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Routes from "packs/routes";
import { download } from "tools";

export default {
  name: "rich-text-note-modal",
  components: {},
  props: {
    photo: Object,
    photos: Array,
    deleteNotePhoto: Function,
    updateNotePhoto: Function,
  },
  data() {
    return {
      edit: false,
      photoName: "",
      isLoading: false,
      myName: this.photo.name
    };
  },
  watch: {
    photo(photo) {
      this.$refs.img.style.opacity = 0.5;
      let img = new Image();
      img.src = photo?.url;
      img.onload = () => {
        this.myName = photo?.name;
        this.$refs.img.style.opacity = 1;
      }
    }
  },
  computed: {
    path() {
      return Routes.photo_path(this.photo.id);
    },
    name: {
      get() {
        return (this.myName || '').replace(/\.[^.]+$/, "");
      },
      set(value) {
        this.myName = value;
        this.photoName = value;
      }
    }
  },
  methods: {
    downloadFile() {
      download(this.photo.url);
    },
    updatePhoto() {
      this.$store?.dispatch("updatePhoto", {
        id: this.photo.id,
        name: this.photoName
      });
      this.updateNotePhoto(this.photo?.id, this.photoName)
    },
    deletePhoto() {
      this.deleteNotePhoto(this.photo?.id);
    }
  }
};
</script>

<style scoped lang="scss">
  .rich-text-note-modal {
    .modal-body {
      align-self: center;
      position: relative;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: 2rem;
      max-height: 80vh;
      max-width: 80vw;
      min-width: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      overflow: hidden;
      background-color: white;
      @include media("<tablet") {
        max-width: 95vw;
      }
      @include media("<=phone") {
        max-width: 100vw;
      }
    }

    &__container {
      width: unset !important;
      height: unset !important;
      display: flex;
      flex-direction: column;
      min-width: 100px;
      min-height: 100px;
      @include media("<tablet") {
        min-width: unset;
        min-height: unset;
      }
    }

    &__img {
      object-fit: cover;
      max-width: 500px;
      max-height: 500px;
    }

    &__previous {
      left: 20px;
    }

    &__next {
      right: 20px;
    }

    &__next,
    &__previous {
      position: absolute;
      width: 6vmax;
      height: 6vmax;
      max-width: 47px;
      max-height: 47px;
      background: white;
      border-radius: 8vmax;
      border: 1px solid lightgrey;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 1;
      &:hover {
        color: var(--blue);
      }

      /* @include media("<tablet") { */
      /*   height: 100%; */
      /*   width: 50%; */
      /*   border-radius: 0px; */
      /*   opacity: 0; */
      /* } */
    }
  }
</style>
