<template>
  <div
    @click="active = !active"
    class="c-dropdown"
    :class="{'c-dropdown--active': active}"
    v-click-outside="clickOutside"
  >
    <div v-show="active" class="c-dropdown__container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'c-dropdown',
  components: {
  },
  props: {},
  data() {
    return {
      active: false
    };
  },
  computed: {
  },
  methods: {
    clickOutside() {
      this.active = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'base';

.c-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 6px;
  border: 1px solid rgba(0,0,0,0);
  background-image: url("~base/vertical-dots.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:hover, &:active, &--active {
    border: 1px solid black;
  }

  &__container {
    z-index: 100;
    background-color: white;
    position: absolute;
    right: -1px;
    top: 44px;
    border-radius: 6px;
    border-bottom: 1px solid var(--gray2);

    & > div {
      display: flex;
      align-items: center;
      width: 180px;
      padding: 10px;
      border: 1px solid var(--gray2);
      border-bottom: 0;
      border-radius: 6px;
      &:hover {
        background-color: var(--gray5);
      }

    div:nth-of-type(1) {
      display: flex;
      margin-right: 10px;
      align-items: center;
    }
    div:nth-of-type(2) {
      @include title1;
      display: flex;
      flex: 1;
    }
  }

  }


}
</style>
