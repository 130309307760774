export function confirmation(msg, callback) {

  let closeConfirmation = e => {
    if (e.target.classList.contains("j-general-confirmation-trigger")) {
      elem = document.querySelector(".j-general-confirmation");
      if (elem) {
        document.body.removeChild(elem);
      }
      callback(false);
    }
  };

  let confirmConfirmation = e => {
    if (e.target.classList.contains("j-general-confirmation-trigger")) {
      e.stopPropagation();
      elem = document.querySelector(".j-general-confirmation");
      if (elem) {
        document.body.removeChild(elem);
      }
      callback(true);
    }
  };

  let elem = document.createElement("div");
  elem.classList.add("c-confirmation");
  elem.classList.add("j-general-confirmation");
  elem.classList.add("j-general-confirmation-trigger");
  let inner = document.createElement("div");
  inner.classList.add("c-confirmation__box");
  inner.classList.add("c-box");
  let titleContainer = document.createElement("div");
  let title = document.createElement("h2");
  let xButton = document.createElement("img");
  xButton.classList.add("x-button");
  xButton.classList.add("j-general-confirmation-trigger");
  xButton.src = require("base/x.svg");
  title.innerHTML = msg;
  titleContainer.classList.add("c-confirmation__title-container");
  titleContainer.appendChild(title);
  titleContainer.appendChild(xButton);
  let cancelButton = document.createElement("div");
  cancelButton.classList.add("c-button");
  cancelButton.classList.add("c-button--white");
  cancelButton.classList.add("j-general-confirmation-trigger");
  cancelButton.style.marginRight = '20px';
  cancelButton.style.flex = '1';
  cancelButton.innerHTML = "Cancel";
  cancelButton.addEventListener("click", closeConfirmation);
  let okButton = document.createElement("div");
  okButton.classList.add("c-button");
  okButton.classList.add("j-general-confirmation-trigger");
  okButton.style.flex = '1';
  okButton.innerHTML = "Ok";
  okButton.addEventListener("click", confirmConfirmation);
  let buttonContainer = document.createElement("div");
  buttonContainer.classList.add("c-confirmation__button-container");
  buttonContainer.appendChild(cancelButton);
  buttonContainer.appendChild(okButton);

  inner.appendChild(titleContainer);
  inner.appendChild(buttonContainer);

  elem.appendChild(inner);

  elem.addEventListener("click", closeConfirmation);
  document.body.appendChild(elem);
  return callback;
}

export function ifConfirmed(msg, callback) {
  confirmation(msg, success => {
    if (success) callback();
  })
}
