<template>
  <div class="flex column">
    <RichTextNotePhotosModal
      v-if="showModal"
      @close="showModal = false"
      :photo="notePhotos[currentIndex]"
      @change="changeCurrent"
      :deleteNotePhoto="removePhoto"
      :updateNotePhoto="updatePhoto"
    />
    <div
      id="richText"
    ></div>
    <input
      ref="file"
      style="display: none"
      type="file"
      @change="uploadPhoto"
    />
    <div class="flex photos-header">
      <h3 class="mb10 row-col">Photos</h3>
      <cButton
          @click="$refs.file.click()"
          white
          upload
          style="max-width: 130px;"
          class="ml10"
        >
          Upload
        </cButton>
    </div>
    <div class="photos-list">
      <div v-for="(photo, index) in notePhotos">
        <img
          :src="photo.url"
          style="max-width: 200px; max-height: 80px;"
          @click="
            currentIndex = index;
            showModal = true;
          "
        />
        <cIcon @click="removePhoto(photo.id)" class="ml10" button img="trash"></cIcon>
      </div>
    </div>
  </div>
</template>

<script>
  import { objectToFormData } from "object-to-formdata";
  import Routes from "packs/routes.js.erb";
  import autosize from "autosize";
  import Quill from 'quill';
  import 'quill/dist/quill.snow.css';
  import { debounce } from "lodash";
  import RichTextNotePhotosModal from "./rich_text_note_photos_modal";
  import { ifConfirmed } from "tools/confirmation";

  export default {
    name: "c-richtextarea",
    mounted() {
      autosize(this.$el);
    },
    components: {
      RichTextNotePhotosModal
    },
    props: {
      value: String,
      disabled: Boolean,
      invalid: Boolean,
      name: String,
      submitPath: String,
      submitModel: String,
      note: Object,
      notePhotos: Array,
      deletePhoto: Function,
    },
    data () {
      return {
        richTextMessage: this.value,
        debounceDelay: 300,
        showModal: false,
        currentIndex: 0
      };
    },
    computed: {
    },
    methods: {
      updateValue(value) {
        this.$emit("input", value);
      },
      submit(value) {
        this.$emit("change", value);
        if (this.$props.submitPath && !this.$props.invalid) {
          this.$http
            .patch(this.$props.submitPath, {
              [this.$props.submitModel]: { [this.$props.name]: value }
            })
            .then(
              () => {},
              response => {
                for (const error in response.body) {
                  this.$set(this.$data.errors, error, response.body[error][0]);
                }
              }
            );
        }
      },
      initQuill() {
        const toolbarOptions = [
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['clean'],
        ];

        this.quill = new Quill('#richText', {
          modules: {
            toolbar: toolbarOptions,
          },
          theme: 'snow',
        });

        this.quill.root.innerHTML = this.richTextMessage;
        
      const debouncedHandler = debounce(() => {
        const updatedValue = this.quill.root.innerHTML;
        this.richTextMessage = updatedValue;
        this.updateValue(updatedValue);
        this.submit(updatedValue);
      }, this.debounceDelay);

        this.quill.on('text-change', debouncedHandler);
      },
      uploadPhoto() {
        if (this.notePhotos) {
          this.notePhotos.push(this.$refs.file.files[0]);
        } else {
          this.notePhotos = [this.$refs.file.files[0]];
        }
        const formData = objectToFormData({
          photo: {
            parent_type: "RichTextNote",
            parent_id: this.note.id,
            file: this.notePhotos[this.notePhotos?.length - 1]
          }
        });
        this.$http.post(Routes.photos_path(), formData).then(
          response => {
            this.flashMessage.success({
              title: `Photo uploaded!`
            });
            this.notePhotos.pop();
            this.notePhotos.push(response.body);
          },
          response => {
            this.flashMessage.error({
              title: `Photo not uploaded`,
              message: response.body.errors.join("\n")
            });
            this.notePhotos.pop()
          }
        );
      },
      removePhoto(id){
        ifConfirmed("Are you sure?", () => {
          this.deletePhoto(id);
          this.$store?.dispatch("deletePhoto", id);
        })
      },
      updatePhoto(id, photoName){
        
        this.notePhotos = this.notePhotos.map((photo)=>{
        if (photo.id === id){
          photo.name = photoName
        }
        return photo
      });
      },
      changeCurrent(direction) {
        if (this.currentIndex + direction >= this.notePhotos.length ) {
          this.currentIndex = 0;
        } else if (this.currentIndex + direction < 0) {
          this.currentIndex = this.notePhotos.length - 1;
        } else {
          this.currentIndex += direction;
        }
      }
    },
    mounted() {
      this.initQuill()
    },
  };
</script>

<style>
  #richText {
    min-height: 200px;
    max-height: 200px;
    overflow: scroll;
  }

  .photos-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .photos-list {
    border: 1px solid lightgrey;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    min-height: 200px;
    max-height: 200px;
    overflow: scroll;
    min-width: 100%;
    max-width: 100%;
  }
</style>