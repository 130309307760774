<template>
  <transition name="modal">
    <div class="modal">
      <div class="c-color-picker modal-mask">
        <div class="modal-wrapper" @click.self="$emit('close', color)">
          <div class="c-color-picker__container">
            <div :style="{ background: color }">
              <color-picker
                style="width: 220px !important;"
                :color="color"
                :theme="'light'"
                @changeColor="changeColor"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import colorPicker from "@caohenghu/vue-colorpicker";

export default {
  name: "c-color-picker",
  props: {
    baseColor: String
  },
  components: {
    colorPicker
  },
  data() {
    return {
      color: this.baseColor
    };
  },
  computed: {},
  methods: {
    changeColor(color) {
      this.color = color.hex;
    }
  }
};
</script>

<style lang="scss" scoped>
.c-color-picker {
  &__container {
    align-items: center;
    display: flex;
    max-width: 220px !important;
    max-height: 500px !important;
    border-radius: 6px;
    background-color: #1d2024;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }
}
</style>
