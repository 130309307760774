import camelCase from "lodash/camelCase";

export default function registerBaseComponents(Vue) {
  const requireComponent = require.context(
    // The relative path of the components folder
    "../components",
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /c_\w+\.vue$/
  );

  requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    );

    // Register component globally
    Vue.component(
      componentName, componentConfig.default || componentConfig
    );
  });
}
