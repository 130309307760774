<template>
  <div class="c-number-input c-number-input__container">
    <label v-if="label" class="c-number-input__label">
      {{ this.$props.label }}
      <span v-if="required" class="c-number-input__required">●</span>
    </label>
    <div style="position: relative">
      <input
        class="c-number-input__input"
        :name="this.$props.name"
        :class="inputClasses"
        :value="myValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="updateValue($event.target.value)"
        @change="submit"
        @keyup="$emit('keyup', $event)"
      />
      <img
        @click="changeBy(1)"
        class="c-number-input__arrow-up"
        :src="require('base/arrow-up.svg')"
      />
      <img
        @click="changeBy(-1)"
        class="c-number-input__arrow-down"
        :src="require('base/arrow-down.svg')"
      />
      </div>
    </div>
  </template>
<script>
import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";

Vue.use(VueResource);

export default Vue.component("cNumberInput", {
  props: {
    minValue: Number,
    maxValue: Number,
    number: Boolean,
    disabled: Boolean,
    phone: Boolean,
    invalid: Boolean,
    required: Boolean,
    placeholder: String,
    value: [String, Number],
    label: String,
    name: String,
    submitPath: String,
    submitModel: String
  },
  data() {
    return {
      myValue: this.value,
      errors: []
    };
  },
  watch: {
    myValue(newVal, oldVal) {
      if (!/^[0-9 -]*$/.test(String(newVal))) {
        this.myValue = oldVal;
      }
      if (!newVal && newVal !== 0) return;
      if (this.maxValue && newVal > this.maxValue) {
        this.myValue = this.maxValue;
      }
      if ((this.minValue || this.minValue == 0) && newVal < this.minValue) {
        this.myValue = this.minValue;
      }
      this.$emit("input", this.myValue);
    }
  },
  methods: {
    changeBy(val) {
      this.myValue = Number(this.myValue) + val;
      this.submit(this.myValue);
    },
    updateValue(value) {
      this.myValue = value;
    },
    submit() {
      this.$emit("change", this.myValue);
      if (this.$props.submitPath && !this.$props.invalid) {
        this.$http
          .patch(this.$props.submitPath, {
            [this.$props.submitModel]: { [this.$props.name]: this.myValue }
          })
          .then(
            () => {},
            response => {
              for (const error in response.body) {
                this.$set(this.$data.errors, error, response.body[error][0]);
              }
            }
          );
      }
    }
  },
  computed: {
    mask() {
      if (this.$props.phone) return "(###) ###-####";
      if (this.$props.zipcode) return ["#####", "#####-####"];
      return false;
    },
    inputClasses() {
      return {
        "c-number-input__input--error": this.$props.invalid,
        "c-number-input__input--disabled": this.$props.disabled
      };
    }
  }
});
</script>
<style lang="scss" scoped>
.c-number-input {
  &__container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__required {
    font-size: 14px;
    line-height: 0.9;
    color: var(--orange);
    margin-left: 2px;
  }

  &__label {
    display: block;
    font-size: 12px;
    color: var(--gray4);
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__arrow-up {
    position: absolute;
    top: 3px;
    right: 2px;
    width: 20px;
    cursor: pointer;
  }

  &__arrow-down {
    position: absolute;
    bottom: 3px;
    right: 2px;
    width: 20px;
    cursor: pointer;
  }

  &__input {
    $input: &;
    font-weight: 500;
    width: 100%;
    padding-left: 8px;
    background-color: var(--gray1);
    border: 1px solid var(--gray2);
    border-radius: 6px;
    height: 46px;
    line-height: 46px;

    &:focus {
      outline: none;
      background-color: var(--gray1);
      border: 1px solid #1c1c36;
    }

    @at-root .c-number-input:hover & {
      border: 1px solid #1c1c36;
      background-color: #eeeef3;
    }

    &--disabled {
      background-color: var(--gray2);

      @at-root .c-number-input:hover & {
        background-color: var(--gray2);
        border: 1px solid var(--gray2);
      }
    }

    &--error {
      border: 1px solid red !important;
    }
  }
}
</style>
