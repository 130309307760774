import { render, staticRenderFns } from "./c_char_icon.vue?vue&type=template&id=38085402&scoped=true"
import script from "./c_char_icon.vue?vue&type=script&lang=js"
export * from "./c_char_icon.vue?vue&type=script&lang=js"
import style0 from "./c_char_icon.vue?vue&type=style&index=0&id=38085402&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38085402",
  null
  
)

export default component.exports