<template>
  <div class="c-char-icon" @click="$emit('click')">
    {{char}}
  </div>
</template>

<script>
export default {
  name: 'c-char-icon',
  props: {
    char: {
      type: String,
      required: true
    }
  },
};
</script>

<style lang="scss" scoped>
.c-char-icon {
  width: 28px;
  height: 28px;
  background-color: #E6F3FF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #0281FF;
  font-weight: 500;
  font-size: 20px;
}
</style>
