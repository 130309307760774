<template>
  <div class="c-sort__container">
    <multiselect
      class="c-sort"
      placeholder="Sort by"
      v-model="selected"
      :maxHeight="400"
      :options="selectOptions"
      :multiple="false"
      :close-on-select="true"
      :show-labels="false"
      :showNoResults="false"
      @close="sort()"
    >
    </multiselect>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";
import Multiselect from "vue-multiselect";

Vue.use(VueResource);
export default Vue.component("cSort", {
  props: {
    value: String,
    options: Array,
    namedOptions: Array,
    submitPath: String,
    search: String
  },
  computed: {
    selectOptions() {
      return this.namedOptions
        ? this.namedOptions.map(opt => opt.value)
        : this.options;
    }
  },
  methods: {
    sort() {
      if(this.selected){
        var snaked_value = this.selected
          .match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
          )
          .map(string => string.toLowerCase())
          .join("_");
        this.$http
          .get(this.submitPath, { params: { q: this.search, s: snaked_value } })
          .then(result => {
            window.location.href = result.url;
          });
      } else {
        this.$http
        .get(this.submitPath, { params: { q: this.search } })
        .then(result => {
          window.location.href = result.url;
        });
      }
    }
  },
  components: { Multiselect },
  data() {
    return {
      selected: this.value
    };
  }
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.c-sort {
  border: none;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.c-sort::v-deep .multiselect__tags {
  padding: 8px 40px 8px 8px;
  border: 1px solid var(--gray2);
  border-radius: 6px;
  height: 46px;
  display: flex;
  align-items: center;

  &:hover {
    border: 1px solid #1c1c36;
  }

  .multiselect__input {
    background-color: #fff;
    margin-bottom: 0px;
  }

  .multiselect__tag-icon {
    background-color: var(--gray4);
    &:after {
      color: var(--dark);
    }
  }

  .multiselect__tag {
    background-color: var(--gray4);
    text-transform: uppercase;
    font-weight: 700;
  }
  .multiselect__single {
    padding: 4px 8px 2px 8px;
    margin: 0px;
    max-width: 190px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
  }
}

.c-sort::v-deep .multiselect__content-wrapper {
  border: none;
  .multiselect__option {
    background-color: #fff;
    border: 1px solid var(--gray2);
    border-bottom: 0;
    border-radius: 5px;
  }
}
.c-sort::v-deep .multiselect__select {
  &:before {
    border-style: none;
    margin: 0px;
    position: absolute;
    right: 10px;
    top: 11px;
    color: var(--blue);
    font-size: 21px;
    content: "⌄";
  }
}

.c-sort.multiselect--active::v-deep {
  .multiselect__tags {
    border: 1px solid #1c1c36;
  }
}

.c-sort::v-deep .multiselect__content {
  border-bottom: 1px solid var(--gray2);

  .multiselect__option--selected {
    background-color: var(--gray1);
    color: var(--blue);
  }
  .multiselect__option--highlight {
    background-color: var(--gray1);
    color: var(--blue);
  }
  .multiselect__option {
    span {
      font-weight: 700;
    }
  }
}
</style>
