<template>
  <div
    @click="openLink"
    class="notifications-item"
    :class="{'notifications-item--read': notification.read}"
  >
    <div class="notifications-item__buttons">
      <cIcon
        size="small"
        img="trash"
        @click.native.stop="destroy"
      />
      <cIcon
        v-if="!notification.read"
        size="small"
        img="x"
        @click.native.stop="mark"
      />
    </div>
    <div class="notifications-item__icon">
      <img
        v-if="notification.category == 'ok'"
        :src="require('base/circle_ok.svg')"
      />
      <img
        v-if="notification.category == 'alert'"
        :src="require('base/circle_alert.svg')"
      />
      <img
        v-if="notification.category == 'info'"
        :src="require('base/circle_info.svg')"
      />
    </div>
    <div class="notifications-item__content-container">
      <div class="notifications-item__content">
        {{ notification.content }}
      </div>
      <div
        v-if="notification.description"
        class="notifications-item__description"
      >
        {{ notification.description }}
      </div>
    </div>
  </div>
</template>

<script>
import Routes from "packs/routes";
import { mapGetters } from "vuex";

export default {
  name: "notifications-item",
  components: {},
  props: {
    notification: Object
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    mark() {
      this.$emit('mark', this.notification.id)
    },
    destroy() {
      this.$emit('destroy', this.notification.id)
    },
    openLink() {
      if (!this.notification.link) return;
      window.location = this.notification.link;
    }
  }
};
</script>

<style scoped lang="scss">
.notifications-item {
  $root: &;

  width: 315px;
  padding: 3px;
  border-radius: 6px;
  background-color: var(--gray5);
  display: flex;
  margin-bottom: 5px;
  min-height: 88px;
  display: flex;
  cursor: pointer;

  &--read {
    background-color: white;
    border: 1px solid var(--gray2);
  }

  &__icon {
    flex: 0 0 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 14px !important;
    line-height: 16px;
    padding-right: 25px;
  }

  &__content {
    font-weight: 500;
    color: var(--dark);

    #{$root}--read & {
      font-weight: 400;
    }
  }

  &__description {
    color: var(--gray4);
    margin-top: 7px;
  }

  &__buttons {
    position: absolute;
    right: 10px;
    display: flex;
    flex-direction: column;
  }
}
</style>
