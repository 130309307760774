<template>
  <span :class="classes" class="c-badge" :style="customColorStyle">
    <slot />
  </span>
</template>

<script>
  import { hexToRgb } from "tools";
  export default {
    name: 'c-badge',
    props: {
      color: {
        type: String,
        default: 'dark'
      },
      big: Boolean,
      customColor: String
    },
    computed: {
      classes() {
        return {
          [`c-badge--${this.color}`]: true,
          'c-badge--big': this.big
        }
      },
      customColorStyle() {
        if(this.customColor === undefined){ return '' }
        return `color: ${this.customColor}; background-color: rgba(${hexToRgb(this.customColor)}, 0.1)`
      }
    }
  };
</script>

