<template>
  <div class="c-file-uploader">
    <input ref="input" style="display: none" type="file" @change="onChange" />
    <slot :choose="choose" :uploading="uploading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { objectToFormData } from 'object-to-formdata';

export default {
  name: "c-file-uploader",
  components: {},
  props: {
    path: {
      type: String,
      require: true
    },
    requestType: {
      type: String,
      default: 'post'
    },
    submitModel: {
      type: String,
      require: true
    },
    name: {
      type: String,
      require: true
    },
    params: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      file: null,
      uploading: false
    };
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    choose() {
      if (this.uploading) return;
      this.$refs.input.click()
    },
    onChange() {
      this.file = this.$refs.input.files[0];
      if (!this.file) return;

      this.uploading = true;
      this.$emit('file-uploading');

      const formData = objectToFormData({
        [this.submitModel]: {
          ...this.params,
          [this.name]: this.file
        }
      })

      this.$http[this.requestType](this.path, formData)
        .then(
          (response) => {
            this.uploading = false;
            this.$emit('file-uploaded', {...response.body, success: true})
          },
          (response) => {
            this.uploading = false;
            this.$emit('file-uploaded', {...response.body, success: false})
          },
        )
    }
  }
};
</script>

<style scoped lang="scss">
.c-file-uploader {
  &__container {
  }
}
</style>
