<template>
  <span class="caddress acenter" v-on:click="openInGoogleMaps(address)">
    <div>
      <img class="caddress__image" :src="require('base/navigation.svg')" />
    </div>
    <div>{{ this.format }}</div>
  </span>
</template>

<script>
import { states } from "tools/constants";
export default {
  name: "c-address",
  props: {
    address: Object,
  },
  computed: {
    states() {
      return states;
    },
    format() {
      var state = this.states.find(
        (state) =>
          state.name === this.address.state ||
          state.value === this.address.state
      );
      if (state == "undefined") {
        const state = null;
      }
      return `${this.safeValue(this.address.street)}
              ${this.safeValue(this.address.city)},
              ${this.safeValue(state)}
              ${this.safeValue(this.address.zip_code)}
            `;
    },
  },
  methods: {
    openInGoogleMaps(address) {
      const url = `https://www.google.com/maps/search/?api=1&query=${address.street},+${address.city},+${address.state},+${address.zip_code}`;
      window.open(url, "_blank");
    },
    safeValue(value) {
      if (value == null) return "";
      if (typeof value == "object") return value.value;
      return value;
    },
  },
};
</script>
<style  scoped lang="scss">
.caddress {
  display: flex;

  &:hover {
    cursor: pointer;
  }

  &__image {
    margin-right: 10px;
  }

  @include media("<tablet") {
    margin-left: 0;
    margin-top: 0px;
  }
  @include title2;

  @include media(">tablet", "<desktop") {
    margin-left: 0;
    margin-top: 10px;
    height: 20px;
    input:first-child {
      margin-left: 0px;
    }
  }
}
</style>
