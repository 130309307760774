<template>
  <div class="c-select__container">
    <label v-if="label" class="c-label">
      {{ this.$props.label }}
      <span v-if="required" class="c-select__required">●</span>
    </label>
    <multiselect
      class="c-select"
      :class="classes"
      :disabled="this.disabled"
      :placeholder="this.placeholder"
      :maxHeight="400"
      v-model="selected"
      :options="selectOptions"
      :multiple="false"
      :close-on-select="true"
      :show-labels="false"
      :custom-label="customLabel"
      @input="updateSelected()"
      :showNoResults="false"
      @open="$emit('open')"
      :allowEmpty="allowEmpty"
    >
      <template v-if="noOptions || noOptions === ''" slot="noOptions">{{noOptions}}</template>
    </multiselect>
  </div>
</template>

<script>
// https://vue-multiselect.js.org
import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";
import Multiselect from "vue-multiselect";

Vue.use(VueResource);
export default Vue.component("cSelect", {
  props: {
    noOptions: String,
    allowEmpty: {
      type: Boolean,
      default: true
    },
    releasedWidth: {
      type: Boolean,
      default: false
    },
    selectStyle: String,
    value: [String, Number, Object],
    name: String,
    options: Array,
    namedOptions: Array,
    invalid: Boolean,
    label: String,
    submitPath: String,
    submitModel: String,
    disabled: Boolean,
    required: Boolean,
    placeholder: {
      type: String,
      default: ""
    }
  },
  computed: {
    classes() {
      return {
        "c-select--released-width": this.releasedWidth,
        "c-select--error": this.invalid,
        "c-select--category": "category" == this.selectStyle,
        "c-select--area": "area" == this.selectStyle
      };
    },
    customLabel() {
      return this.$props.namedOptions
        ? opt => (this.namedOptions.find(o => o.value == opt) || {}).name
        : undefined;
    },
    selectOptions() {
      return this.$props.namedOptions
        ? this.namedOptions.map(opt => opt.value)
        : this.$props.options;
    }
  },
  components: { Multiselect },
  watch: {
    value() {
      this.selected = this.value;
    }
  },
  methods: {
    updateSelected() {
      this.$emit("input", this.$data.selected);
      this.$emit("change", this.$data.selected);
      if (this.invalid) return;
      if (this.$props.submitPath) {
        this.$http
          .patch(this.$props.submitPath, {
            [this.$props.submitModel]: {
              [this.$props.name]: this.$data.selected
            }
          })
          .then(
            response => {},
            response => {
              for (const error in response.body) {
                this.$set(this.$data.errors, error, response.body[error][0]);
              }
            }
          );
      }
    }
  },

  data() {
    return {
      errors: {},
      selected: this.$props.value
    };
  }
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.c-select {
  $root: &;
  border: none;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0px;
  }

  &__required {
    font-size: 14px;
    line-height: 0.9;
    color: var(--orange);
    margin-left: 2px;
  }

  &::v-deep .multiselect {
    $multiselect: &;
    @mixin badge {
      text-transform: uppercase;
      padding: 7px;
      margin-right: 10px;
      border-radius: 5px;
      height: 27px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 5px;
      max-width: 100%;
      text-overflow: ellipsis;
      margin: 0px;
      font-size: 14px;
    }

    &__tags {
      padding: 8px 40px 8px 8px;
      border: 1px solid var(--gray2);
      border-radius: 6px;
      height: 46px;
      display: flex;
      align-items: center;

      @at-root #{$root}--error#{&} {
        border: 1px solid red;
      }

      &:hover {
        border: 1px solid #1c1c36;
        @at-root #{$root}--error#{&} {
          border: 1px solid red;
        }
      }

      &__input {
        background-color: #fff;
        margin-bottom: 0px;
      }

      &__tag {
        background-color: var(--gray4);
        text-transform: uppercase;
        font-weight: 700;
      }

      &__tag-icon {
        background-color: var(--gray4);
        &:after {
          color: var(--dark);
        }
      }
    }

    &__single {
      padding: 4px 8px 2px 8px;
      margin: 0px;
      max-width: 190px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @at-root #{$root}--category#{&} {
        @include badge;
        display: flex;
        width: unset;
        align-items: center;
        color: white;
        background-color: var(--dark);
        display: block !important;
        line-height: 13px;
      }
      @at-root #{$root}--area#{&} {
        @include badge;
        display: flex;
        width: unset;
        align-items: center;
        color: white;
        background-color: #adb3c4;
        display: block !important;
        line-height: 13px;
      }
    }

    &__content-wrapper {
      border: none;

      @at-root #{$root}--released-width#{&} {
        width: unset;
      }
    }

    &__option {
      background-color: #fff;
      border: 1px solid var(--gray2);
      border-bottom: 0;
      border-radius: 5px;
      text-overflow: ellipsis;
      overflow: hidden;

      @at-root #{$root}--category#{&} {
        color: white;
      }

      @at-root #{$root}--area#{&} {
        color: white;
      }

      span {
        font-weight: 700;
        @at-root #{$root}--category#{&} {
          @include badge;
          background-color: var(--dark);
        }
        @at-root #{$root}--area#{&} {
          @include badge;
          background-color: #adb3c4;
        }
      }

      &--selected {
        background-color: var(--gray1);
        color: var(--blue);

        @at-root #{$root}--category#{&} {
          color: white;
        }

        @at-root #{$root}--area#{&} {
          color: white;
        }
      }

      &--highlight {
        background-color: var(--gray1);
        color: var(--blue);
        @at-root #{$root}--category#{&} {
          color: white;
        }

        @at-root #{$root}--area#{&} {
          color: white;
        }
      }
    }

    &__select {
      background-color: transparent !important;
      &:before {
        border-style: none;
        margin: 0px;
        position: absolute;
        right: 10px;
        top: 11px;
        color: var(--blue);
        font-size: 21px;
        content: "⌄";
      }
    }

    &__content {
      border-bottom: 1px solid var(--gray2);
      width: 100%;
    }

    &__placeholder {
      font-size: 16px;
      color: var(--black);
      font-weight: 500;
      margin-bottom: 2px;
      margin-left: 5px;
    }
  }
}
</style>
