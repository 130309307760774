<template>
  <div>
    <cInput
      date
      v-model="myDate"
      class="c-datepicker c-datepicker__input"
      :label="label"
      :disabled="disabled"
      :style="chooseBackground"
      :enlarged="true"
      @change="inputCleared"
      @click.native="showModal()"
    />
    <DatePicker
      color="#0281FF"
      class="c-datepicker"
      v-if="show"
      v-model="myDate"
      :format="formatDate"
      :max="max"
      :min="min"
      @close="closeModal"
      @input="updateDate"
    />
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import DatePicker from "vue-md-date-picker";
import moment from "moment-timezone";

export default Vue.component("cDatepicker", {
  props: {
    label: String,
    disabled: Boolean,
    date: {
      type: [Date, String],
      default: null,
    },
    value: {
      type: [Date, String],
      default: null,
    },
    min: String,
    max: String,
    color: String,
    submitModel: String,
    submitPath: String,
    name: String,
  },
  components: { DatePicker },
  data() {
    return {
      errors: {},
      myDate: this.displayDate(),
      show: false,
    };
  },
  computed: {
    chooseBackground() {
      return {
        white: "background-color: white",
      }[this.color];
    },
    valueDate() {
      if (this.myDate == "") {
        return null;
      }
      return moment.tz(new Date(this.myDate), "EST").format();
    },
  },
  methods: {
    closeModal() {
      this.show = false;
      document.body.style.overflow = "";
    },
    formatDate(date) {
      return moment.utc(date).format("L");
    },
    displayDate() {
      const date = this.date || this.value;
      return date == null || date == "Invalid Date"
        ? null
        : moment(date).format("L");
    },
    showModal() {
      if (this.disabled) {
        return;
      }
      this.$data.show = true;
    },
    inputCleared(val) {
      if (!val || val == "") {
        this.$emit("input", null);
        this.$emit("change", null);
      }
    },
    updateDate(value) {
      //TODO first emit is causing deprecation warnings from moment, but only started doing so after being moved to this method?
      this.$emit("input", this.valueDate);
      this.$emit("change", moment(value));

      if (this.submitPath && this.submitModel) {
        this.$http
          .patch(this.submitPath, {
            [this.submitModel]: { [this.name]: this.valueDate },
          })
          .then(
            (response) => {
              this.$emit("updated", response.body);
            },
            (response) => {
              for (const error in response.body) {
                this.$set(this.$data.errors, error, response.body[error][0]);
              }
            }
          );
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.c-datepicker::v-deep thead {
  text-align: center;
}
.c-datepicker::v-deep td {
  text-align: center;
}
.c-datepicker {
  &__input {
    @include media("<tablet") {
      pointer-events: none;
    }
  }
}
</style>
