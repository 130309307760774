import contentDisposition from "content-disposition";

export function hexToRgb(color) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
}

export function authorizeCompanyAdmin(currentUser) {
  if (currentUser.company_admin) {
    return true;
  } else {
    window.EventBus.$emit("authError");
    return false;
  }
}

export function download(url) {
  let filename;
  window
    .fetch(url, { method: "GET", redirect: 'follow' })
    .then(resp => {
      let cd = resp.headers.get("content-disposition")
      if (!cd) {
        const url = new URL(resp.url)
        cd = url.searchParams.get('response-content-disposition')
      }
      filename = contentDisposition.parse(cd).parameters.filename;
      return resp.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
}

export function statusColor(currentMilestone) {
  if (currentMilestone === null || currentMilestone === undefined) {
    return "#A4A8B7";
  }
  return currentMilestone.color;
}
