<template>
  <div class="l-header">
    <div class="l-header__page-title">
      <div class="l-header__burger j-navbar-toggle" @click="toogleNav($event)">
        <img :src="require('base/hamburger_icon.svg')">
      </div>
      CustomEase
    </div>
    <div class="l-header__buttons">
      <div class="l-header__search-button">
        <!-- <img :src="require('base/search.svg')"> -->
      </div>
      <div class="l-header__notification-button">
        <Notifications :current-user.sync="myCurrentUser"/>
      </div>
      <div class="l-header__avatar-placeholder">
        <cAvatar :name="myCurrentUser.name" :color="myCurrentUser.color" />
      </div>
      <div class="l-header__user">
        <div>
          <div class="l-header__user-name title1">
            {{ myCurrentUser.name }}
          </div>
          <div class="l-header__user-position">
          <span class="l-header__user-title">
            {{ myCurrentUser.role }}
          </span>
          </div>
        </div>
      </div>
      <div class="l-header__options">
        <cUserOptions :current-user="currentUser" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";
import Notifications from "./user_navbar/notifications";

Vue.use(VueResource);

export default Vue.component("cUserNavbar", {
  components: {
    Notifications
  },
  props: {
    currentUser: Object
  },
  data() {
    return {
      myCurrentUser: { ...this.currentUser }
    }
  },
  computed: {},
  methods: {
    toogleNav: function(event) {
      let navbar = document.querySelector("nav.l-navbar");
      let shade = document.querySelector(".l-navbar__shade");

      if (navbar.classList.contains("l-navbar--show")) {
        navbar.classList.remove("l-navbar--show");
        shade.classList.remove("l-navbar__shade--show");
      } else {
        navbar.classList.add("l-navbar--show");
        shade.classList.add("l-navbar__shade--show");
      }
    }
  },
  mounted(){
    window.EventBus.$on('currentUserChange', (user) => {
      this.myCurrentUser = user;
    });
  }
})
</script>

<style lang="scss" scoped>

</style>
