<template>
  <img :style="resize" @click="$emit('click')" :src="src" :class="classes" />
</template>

<script>
export default {
  name: "c-icon",
  props: {
    button: {
      type: Boolean,
      default: false
    },
    img: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: "normal"
    }
  },
  computed: {
    classes() {
      return {
        "c-icon--button": this.button
      };
    },
    resize() {
      return {
        normal: "width: 28px; height: 28px",
        small: "width: 18px; height: 22px"
      }[this.size];
    },
    src() {
      return {
        download: require("base/download.svg"),
        "file-whole": require("base/file_whole.svg"),
        file: require("base/file.svg"),
        edit: require("base/edit.svg"),
        trash: require("base/trash.svg"),
        x: require("base/x.svg"),
        block: require("base/block.svg"),
        unblock: require("base/unblock.svg"),
        "x-blue": require("base/x-blue.svg"),
        "arrow-down": require("base/arrow-down.svg"),
        "arrow-up": require("base/arrow-up.svg")
      }[this.img];
    }
  }
};
</script>

<style lang="scss" scoped>
.c-icon {
  &--button {
    cursor: pointer;
  }
}
</style>
